<template>
  <div class="mt-1">
    <div v-if="isWizardReady">
      <!-- STATUS: {{ sts }} -->
      <wizard
        :resourceId="parseInt(id)"
        repository="communication_procedure"
        :title="wizardProps.title"
        :subtitle="wizardProps.subtitle"
        :color="wizardProps.color"
        :startIndex="wizardProps.startIndex"
        :extras="{ status_procedure: sts, communication_type }"
        :tabs="wizardTabs"
        @fetch="loadIndex()"
        @status="onStatus"
      >
      </wizard>
    </div>
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          title="---"
        >
          <!-- :title="getDefaultFilterMessage()" -->
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
    </b-row>
    <b-collapse _visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-select
                name="scartate"
                label="Scartate"
                :options="discardedOptions"
                v-model="filter.byCommunicationDetail.is_discarded"
              />
            </div>
            <div class="col-md-3">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Anagrafica"
                      label="Anagrafica"
                      v-model="form.inpt_label_registry"
                      :readonly="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchRegistry"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      v-if="form.inpt_label_registry"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetRegistryId"
                      title="Elimina Anagrafica"
                      ><b-icon icon="trash"></b-icon>
                    </b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
            </div>
            <div class="form-group col-md-3">
              <span
                v-if="registry_data"
                class="info"
                v-html="toInfoData(registry_data, 'registry')"
              >
              </span>
            </div>
            <quick-search-registry-modal
              ref="quickSearchRegistry"
              @input="handleRegistryModalInput"
            >
            </quick-search-registry-modal>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    <div v-if="isWizardReady">
      <communication-groupings
        :filterOn="{
          byRelations: [
            'byRegistry',
            'byInsurancePayment',
            'byBookEntry',
            'byInsuranceAncillary',
          ],
        }"
        :fields="fields"
        repository="communication_grouping"
        resource="communication_groupings"
        :filterName="filterName"
        :ref="tableRef"
        :onlyActions="['infomodal']"
        @loadIndex="loadIndex"
        :extras="{
          status_procedure: sts,
          communication_type,
        }"
      ></communication-groupings>
    </div>
  </div>
</template>

<script>
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import CommunicationGroupings from "@/components/tables/CommunicationGroupings";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import Wizard from "@/components/communication/procedures/Wizard";
import { toInfoData } from "@/utils/transforms";
import { mapActions } from "vuex";

export default {
  mixins: [FormMixin, ShortcutMixin, CustomFiltersMixin],
  data() {
    return {
      sp: null, //status_procedure model for child components (injected within extras)
      isLoading: true, // to skip autofetch of table since component is mounted already with a filter set by route
      isWizardReady: false,
      repository: "communication_procedure",
      resource: "communication_procedures",
      filter: this.initFilter(),
      filterName: "filterCommunicationGroupings",
      id: this.$route.params.id, // communication_procedures.id
      status_procedure: this.$route.params.status_procedure,
      communication_type: this.$route.params.communication_type,
      tableRef: "CommunicationProceduresTableRef",
      registry_data: null,
      form: {
        inpt_label_registry: "",
        communication_procedure: {
          status_procedure: null,
          communication_type: null,
        },
      },
      fields: [
        {
          key: "registry",
          label: "Destinatario",
        },
        {
          key: "address",
          label: "Indirizzo",
        },
        {
          key: "address_books",
          label: "Contatti",
        },
        {
          key: "details",
          label: "Righe",
        },
        {
          key: "note",
          label: "Note",
        },
        {
          key: "total",
          label: "Totale",
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        // {
        //   key: "communication_procedure_id",
        //   label: "ID Procedura",
        // },
      ],
      discardedOptions: [
        { text: "SI", value: "Y" },
        { text: "NO", value: "N" },
      ],
      wizardProps: {
        startIndex: 1,
        title: "Procedura guidata",
        subtitle: `Stai inviando per ${this.getCommunicationTypeText()}`,
        nextButtonText: "Prossimo",
        backButtonText: "Precedente",
        finishButtonText: "Finisci",
        // stepSize: {
        //   type: String,
        //   default: "md",
        //   validator: (value) => {
        //     let acceptedValues = ["xs", "sm", "md", "lg"];
        //     return acceptedValues.indexOf(value) !== -1;
        //   },
        // },
        /***
         *  Sets validation (on/off) for back button. By default back button ignores validation
         */
        validateOnBack: Boolean,
        /***
         * Applies to text, border and circle
         */
        color: "orange", //circle, border and text color
        /***
         *  Is set to current step and text when beforeChange function fails
         */
        errorColor: "#8b0000",
        /**
         * Can take one of the following values: 'circle|square|tab`
         */
        shape: "circle",
        /**
         * name of the transition when transition between steps
         */
        // transition: {
        //   type: String,
        //   default: "", //name of the transition when transition between steps
        // },
        /***
         * Index of the initial tab to display
         */
        // startIndex: {
        //   type: Number,
        //   default: 0,
        // },
      },
      // wizardTabProps: {
      //   title: {
      //     type: String,
      //     default: "",
      //   },
      //   /***
      //    * Icon name for the upper circle corresponding to the tab
      //    * Supports themify icons only for now.
      //    */
      //   icon: {
      //     type: String,
      //     default: "",
      //   },
      //   /***
      //    * Only render the content when the tab is active
      //    */
      //   lazy: {
      //     type: Boolean,
      //     default: false,
      //   },
      //   /***
      //    * Function to execute before tab switch. Return value must be boolean
      //    * If the return result is false, tab switch is restricted
      //    */
      //   beforeChange: {
      //     type: Function,
      //   },
      // },
      // status_procedure: {
      //   0: "Inzio",
      //   1: "Esclusioni",
      //   2: "Raggruppamenti",
      //   3: "Modifica",
      //   4: "Template",
      //   5: "Invio",
      // },
      wizardTabs: [
        {
          title: "Esclusioni",
          icon: "b-dash-circle",
          component: "Step1",
          lazy: true,
          index: 0,
        },
        {
          title: "Raggruppamenti",
          icon: "b-grid",
          component: "Step2",
          lazy: true,
          hide: false,
          index: 1,
        },
        {
          title: "Modifica",
          icon: "b-pencil-square",
          component: "Step3",
          lazy: true,
          index: 2,
        },
        {
          title: "Template",
          icon: "b-file-earmark-medical",
          component: "Step4",
          lazy: true,
          index: 3,
        },
      ],
    };
  },
  components: {
    BaseInput,
    BaseSelect,
    CommunicationGroupings,
    QuickSearchRegistryModal,
    Wizard,
  },
  methods: {
    toInfoData,
    getCommunicationTypeText() {
      const ct =
        this.communication_type ?? this.$route.params.communication_type;
      let text = "";
      switch (ct) {
        case 0:
          text = "Email";
          break;
        case 2:
          text = "SMS";
          break;
        case 3:
          text = "Posta";
          break;
        default:
          text = "";
          break;
      }
      return text;
    },
    initFilter() {
      let init = {
        // byAttribute: {
        //   communication_procedure_id: this.$route.params.id,
        // },
        byCommunicationProcedure: {
          id: this.$route.params.id,
        },
        // byCommunicationDetail: {
        //   is_discarded_by_broker: "N",
        //   is_discarded_by_default_method: "N",
        // },
        byCommunicationDetail: {
          // is_discarded_by_broker: "N",
          // is_discarded_by_default_method: "N",
          is_discarded: "N",
        },
        // byDiscarded: "N",
        byRegistry: {
          id: null,
        },
      };
      return init;
    },
    onStatus(status) {
      // inietto il nuovo valore nella table, che tramite prop extras vien passato al tab General
      console.debug("onStatus: ", status);
      // this.isWizardReady = false;
      this.sp = status;
      // this.$refs[this.tableRef].sp = status;
      // this.isWizardReady = true;
    },
    onSubmit() {
      this.isLoading = true;
      this.update(this.repository, this.id)
        .then(() => {
          // this.$store.dispatch("auth/book_tags");
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: procedura aggiornata`,
          });
          // this.shortcut("book_tags.index", null, null, "filterBookTags");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadIndex() {
      console.log("loadIndex");
      this.isLoading = true;
      let name = this.filterName;
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef]
        .fetch()
        .then(() => {
          this.removeFilterByName(name);
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        });
      // const Repo = RepositoryFactory.get("communication_grouping");
      // let queryString = `byRegistry&byCommunicationProcedure[id]=${this.id}`;
      // Repo.index(queryString)
      //   .then((response) => {
      //     this.$refs[this.tableRef].items = response.data.data;
      //     this.isLoading = false;
      //   })
      //   .catch((error) => {
      //     let errMsg = this.$getErrorMessage(error);
      //     this.$showSnackbar({
      //       preset: "error",
      //       text: errMsg,
      //     });
      //   });

      // this.fetchEditForm(this.repository, this.id).then(() => {
      //   this.isLoading = false;
      // });
    },
    setWizardStep(number) {
      // Step1, Step2, ...
      this.wizardProps.startIndex = number;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      // this.resetFilter();
      this.resetPagination();
      this.resetRegistryId();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      this.filter = this.initFilter();
      // this.onSearch(name);
    },
    // onDestroyDetail(id) {
    //   const Repo = RepositoryFactory.get("communication_detail");
    //   Repo.destroy(id)
    //     .then(() => {
    //       this.loadIndex();
    //       this.$showSnackbar({
    //         preset: "success",
    //         text: "Dettaglio eliminato con successo",
    //       });
    //     })
    //     .catch((error) => {
    //       let errMsg = this.$getErrorMessage(error);
    //       this.$showSnackbar({
    //         preset: "error",
    //         text: `${errMsg}`,
    //       });
    //     });
    // },
    openQuickSearchRegistry() {
      this.$bvModal.show("quickSearchRegistryModal");
    },
    handleRegistryModalInput(value) {
      this.filter.byRegistry.id = value.id;
      this.registry_data = value;
      this.form.inpt_label_registry = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    resetRegistryId() {
      this.registry_data = null;
      this.form.inpt_label_registry = null;
      this.filter.byRegistry.id = null;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
  },
  beforeMount() {
    console.log(
      this.$route.params.id,
      this.$route.params.status_procedure,
      this.$route.params.communication_type
    );
    // init the status procedure
    this.sp = this.$route.params.status_procedure;
    // se status_procedure è 1,2,3 o 4 (numero di wizardTabs) allora
    //          stepIndex è 0,1,2 o 3
    // altrimenti (status_procedure = 5) stepIndex è 3 (non 4)
    let stepIndex =
      this.status_procedure <= this.wizardTabs.length
        ? this.status_procedure - 1
        : this.wizardTabs.length - 1;
    this.setWizardStep(stepIndex);
    this.isWizardReady = true;
  },
  mounted() {
    this.loadIndex();
  },
  computed: {
    rep() {
      return this.repository;
    },
    sts() {
      return this.sp;
      // return this.$refs[this.tableRef]
      //   ? this.$refs[this.tableRef].status_procedure
      //   : this.sp;
    },
  },
};
</script>
