<template>
  <div class="mt-2">
    <!-- <div :style="[!noInnerWidth ? { width: width } : {}]"> -->
    <div class="table-wrapper">
      <b-table
        :busy="isLoading"
        head-variant="light"
        :no-local-sorting="true"
        :items="items"
        :fields="fields"
        per-page="0"
        :sticky-header="height"
        no-border-collapse
        responsive
        show-empty
        empty-text="Non ci sono informazioni da mostrare"
        small
      >
        <template #table-busy>
          <div class="text-center my-2">
            <base-icon name="loading" width="35" height="35" />
            <p>Operazione in corso...</p>
          </div>
        </template>
        <template #cell(actions)="row">
          <div class="action-buttons">
            <b-button
              size="sm"
              variant="lisaweb"
              @click="onEdit(row.item.id)"
              class="mt-1 mr-1"
              title="Modifica"
            >
              <b-icon icon="pencil-square"></b-icon>
            </b-button>
            <b-button
              size="sm"
              variant="lisaweb"
              @click="onDestroy(row.item.id)"
              class="mt-1 mr-1"
              title="Elimina"
            >
              <b-icon icon="trash"></b-icon>
            </b-button>
          </div>
        </template>
        <template
          v-slot:head(check)="data"
          v-if="hasChecks && !isCheckExclusive && items.length"
        >
          <base-checkbox
            v-model="checkAll"
            name="all"
            class="check"
            @change="onSelectAllRows()"
            ><span v-if="data.label" v-html="data.label"></span>
          </base-checkbox>
        </template>
        <template #cell(check)="row" v-if="hasChecks">
          <base-checkbox
            name="check"
            class="check"
            v-model="row.item.check"
            @input="onSelectRow($event, row.index, row.item)"
          />
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import template_lisaweb from "./template_lisaweb";
import BaseIcon from "@/components/BaseIcon";
import BaseCheckbox from "@/components/form/BaseCheckbox";

export default {
  name: "PdfTable",
  extends: template_lisaweb,
  components: {
    BaseIcon,
    BaseCheckbox,
  },
};
</script>
